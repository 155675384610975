﻿import { FunctionComponent, ReactNode, useCallback, useRef, useState } from "react";
import { AgGridReact, AgGridReactProps } from "@ag-grid-community/react";
import { EntityCandidate } from "../../../../api/models/EntityCandidate";
import { Badge, Box, Text, useDisclosure } from "@chakra-ui/react";
import { EmailCellRenderer } from "../../../../grid";
import { CandidateStatusCellRenderer } from "./CandidateStatusCellRenderer";
import CandidateStatusFilter from "./CandidateStatusFilter";
import EmploymentStatusFilter from "./EmploymentStatusFilter";
import DesignatedZoneFilter from "./DesignatedZoneFilter";
import { useNavigate } from "react-router-dom";
import { CandidateGridMenuBar } from "./CandidateGridMenuBar";
import { ImportCandidatesModal } from "../ImportCandidatesModal";
import { ColDef, ICellRendererParams, IGetRowsParams, RowClickedEvent, RowSelectedEvent } from "@ag-grid-community/core";
import { AgGridContainer, formatDateStr } from "@am-tax/fe-core";

import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-alpine.css";
import "../../../../assets/css/ag-theme.css";
import { useAreaPermissions } from "../../../../hooks";
import { CandidatesArea } from "../../../../auth";
import { useUserContext } from "../../../../context";
import { axiosInstance } from "../../../../utils";

export interface CandidateGridProps {
    before?: ReactNode;
    clientId: string;
    entityId: string | undefined;
}

export const CandidateGrid: FunctionComponent<CandidateGridProps> = ({ before, clientId, entityId }) => {
    const navigate = useNavigate();
    const { dateFormat } = useUserContext();

    const axios = axiosInstance();

    const areaPermissions = useAreaPermissions(CandidatesArea, clientId);

    const importModal = useDisclosure();

    const fullReadColumns: Array<ColDef<EntityCandidate>> = [
        {
            headerName: "Target Qualification",
            cellRenderer: ({ data }: ICellRendererParams) => {
                if (data?.selectedQualification) {
                    return data.selectedQualification;
                } else {
                    return (
                        <Box>
                            <Badge colorScheme={"red"} lineHeight={"var(--chakra-lineHeights-base)"}>
                                Action Needed
                            </Badge>
                        </Box>
                    );
                }
            },
            minWidth: 120,
            filter: false,
        },
        {
            headerName: "Follow-up",
            field: "openRequestCount",
            cellRenderer: ({ data }: ICellRendererParams) =>
                !!data?.openRequestCount && (
                    <Box>
                        <Badge colorScheme={"red"} lineHeight={"var(--chakra-lineHeights-base)"}>
                            {data?.openRequestCount} Open Requests
                        </Badge>
                    </Box>
                ),
            minWidth: 180,
            filter: "agNumberColumnFilter",
        },
    ];

    const columnDefs: Array<ColDef<EntityCandidate>> = [
        {
            headerName: "First",
            field: "firstName",
            minWidth: 80,
            filter: true,
            sortable: true,
        },
        {
            headerName: "Last",
            field: "lastName",
            minWidth: 80,
            filter: true,
            sortable: true,
        },
        {
            field: "email",
            cellRenderer: EmailCellRenderer,
            minWidth: 140,
            filter: true,
        },
        {
            field: "phone",
            filter: true,
        },
        {
            headerName: "Location",
            cellRenderer: ({ data }: ICellRendererParams) => (
                <Text>
                    {data?.city}, {data?.state} {data?.zipCode}
                </Text>
            ),
        },
        {
            field: "designatedZoneType",
            filter: DesignatedZoneFilter,
        },
        {
            field: "status",
            cellRenderer: CandidateStatusCellRenderer,
            filter: CandidateStatusFilter,
            sortable: true,
        },
        ...(areaPermissions.has(CandidatesArea.read_full) ? fullReadColumns : []),
        {
            field: "employmentStatus",
            cellRenderer: ({ data }: ICellRendererParams) => {
                return data?.employmentStatus;
            },
            filter: EmploymentStatusFilter,
            sortable: true,
        },
        {
            field: "daysRemaining",
            cellRenderer: ({ data }: ICellRendererParams) =>
                data?.daysRemaining !== null &&
                data?.daysRemaining >= 0 && (
                    <Box>
                        <Badge
                            colorScheme={data?.daysRemaining > 21 ? "green" : data?.daysRemaining > 14 ? "yellow" : "red"}
                            variant={"solid"}
                            lineHeight={"var(--chakra-lineHeights-base)"}
                        >
                            {data?.daysRemaining}
                        </Badge>
                    </Box>
                ),
            filter: "agNumberColumnFilter",
            sortable: true,
        },
        {
            headerName: "Completed On",
            field: "questionnaireCompletedOn",
            cellRenderer: ({ data }: ICellRendererParams) => (
                <>
                    <Text>{formatDateStr(data?.questionnaireCompletedOn, dateFormat)}</Text>
                </>
            ),
            filter: "agDateColumnFilter",
            sortable: true,
        },
    ];

    const gridRef = useRef<AgGridReact<EntityCandidate>>(null),
        candidatesGrid: AgGridReactProps<EntityCandidate> = {
            rowModelType: "infinite",
            datasource: {
                getRows: (params: IGetRowsParams) => {
                    axios
                        .get<{ rowsThisBlock: EntityCandidate[]; lastRow?: number }>(
                            `api/Clients/${clientId}/Entities/${entityId ?? "all"}/candidates/grid?query=${JSON.stringify(params)}`
                        )
                        .then(response => {
                            params.successCallback(response.data.rowsThisBlock, response.data.lastRow);
                        });
                },
            },
            defaultColDef: {
                flex: 1,
                filter: false,
                sortable: false,
                resizable: true,
            },
            columnDefs,
            onRowClicked(event: RowClickedEvent<EntityCandidate>) {
                navigate(`/clients/${clientId}/candidates/${event.data?.entityId}/${event.data?.id}`);
            },
            getRowStyle: () => ({
                cursor: "pointer",
            }),
        };

    //Checks for the number of selected checkbox
    const [deleteIsDisabled, setDeleteIsDisabled] = useState(true);
    const onRowSelected = useCallback((event: RowSelectedEvent) => {
        const selectedNodesCount = event.api.getSelectedNodes().length;
        if (selectedNodesCount > 0) {
            setDeleteIsDisabled(false);
        } else {
            setDeleteIsDisabled(true);
        }
    }, []);

    return (
        <AgGridContainer
            gap={0}
            borderTop={"none"}
            borderRadius={0}
            before={
                <Box>
                    {before}
                    <CandidateGridMenuBar
                        grid={gridRef.current}
                        clientId={clientId}
                        entityId={entityId}
                        onImportModalOpen={importModal.onOpen}
                        deleteIsDisabled={deleteIsDisabled}
                    />
                </Box>
            }
        >
            <AgGridReact {...candidatesGrid} ref={gridRef} rowSelection={"multiple"} onSelectionChanged={onRowSelected} />
            {entityId && <ImportCandidatesModal {...importModal} clientId={clientId} entityId={entityId} />}
        </AgGridContainer>
    );
};
